.gps-wrapper {
  position: relative;
}

.gps-ring {
  position: absolute;
  background-color: steelblue;
  top: -15px;
  left: -15px;
  height: 30px;
  width: 30px;
  -webkit-border-radius: 30px;
          border-radius: 30px;
  -webkit-animation: pulsate 2s ease-out;
          animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;

}

.gps-dot {
  position: absolute;
  top: -5px;
  left: -5px;
  background-color: steelblue;
  border: 2px solid white;
  height: 6px;
  width: 6px;
  -webkit-border-radius: 10px;
          border-radius: 10px;
}

@-webkit-keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50% {opacity: 0.5;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}
