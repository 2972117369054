html, body {
  background-color: #fff;
  height: 100%;
}

body {
  // font-family: 'Plus Jakarta Display';
  font-weight: 400;
  font-style: normal;
}

.nav {
  background-color: white;
  height: 64px;
  display: flex;
}

ul {
  list-style: none;
  padding-left: 0px;
}

a {
  text-decoration: none;
}

.MuiTypography-root.disclaimer {
  // max-width: 735px;
  // margin-left: auto;
  // margin-right: auto;
}

.MuiTableContainer-root a {
  color: #2E3A5E;
}

/* Header */
.header-name {
  width: fit-content;
  margin-left: auto;

  p {
    margin-bottom: 0px;
  }
}
/* Header */

/* Sidebar */
.report-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  min-height: 96vh;
  height: 100%;
}

.brand img {
  max-height: 63px;
}

.report-sidebar-navigation {
  margin-top: 41px;
  padding-left: 10px;

  li:not(:last-child) {
    margin-bottom: 25px;
  }

  a {
    display: flex;
    align-items: center;
    color: $section-header;
    font-size: 1.125rem;
    line-height: 30px;
    letter-spacing: 0.36px;
    transition: color 0.2s;

    &:hover {
      color: $primary;
    }
  }

  div {
    display: inline-block;
    margin-left: 12px;
  }
}
/* Sidebar */

/* Main Part */
.section-intro {
  .MuiButton-contained {
    margin-top: 14px;
    margin-bottom: 30px;
  }
  .MuiTypography-paragraph.disclaimer {
    margin-bottom: 50px;
  }
}

.section-market-data {
  .graphic-block {
    margin-top: 30px;
    max-width: 642px;
    margin-left: auto;
    margin-right: auto;

    .MuiBox-root {
      box-shadow: 0px 8px 16px 0px rgba(17,10,99,0.04), 0px 12px 22px 0px rgba(128,118,247,0.04);
      padding: 25px;
      border-radius: 10px;
      height: calc(100% - 53px);
    }
    .UnitBreakdown {
      position: relative;
      text-shadow: 1px 1px 25px gray;

      & > div:first-child {
        position: absolute;
        z-index: 1;
        color: #fff;
        padding-left: 9px;
        line-height: 25px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 12px;
      }
      & > div:last-child {
        height: 25px !important;
        background-color: rgba(130,130,130,0.39) !important;
        border-radius: 1000px;
        overflow: hidden;

        .rect {
          border-radius: 1000px;
          height: 25px !important;
        }
      }
    }
  }
}

.section-swapout,
.section-competition {
  .info-block .MuiBox-root {
    box-shadow: 0px 6px 12px 0px rgba(81,192,168,0.29);
    border-radius: 10px;
    padding: 20px 23px;
  }
}

.section-swapout {
  .info-block {
    margin: 10px auto 0px;
    max-width: 790px;
  }
}

.competition-info-container {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.section-reviews .reviews-container {
  box-shadow: 0px 8px 16px 0px rgba(17,10,99,0.04), 0px 12px 22px 0px rgba(128,118,247,0.04);
  align-items: center;
  margin: -28px auto;
  border: 1px solid whitesmoke;

  svg {
    font-size: 35px !important;
  }
}

.section-website {
  a {
    color: $section-header;
  }
  .MuiCardActions-root a {
    color: $primary;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.36px;
  }
}

/* Main Part */

.MuiGrid-item.report-content {
  margin-left: auto;
  box-shadow: 0px 34px 104px 0px rgba(20,20,43,0.04), 0px 13px 72px 0px rgba(19,18,66,0.02);
  border-radius: 15px;
  border: 1px solid whitesmoke;
}

.left a {
  margin: 15px 10px 20px 10px;
}

.left {
  flex: 1;
}

.billow {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.billow h1 {
  line-height: 50px;
  font-size: 22px;
  margin: 0px;
  font-weight: 500;
}

.right {
  flex: 1;
}



.brand {
  margin-right: 10px;
  padding-left: 0px;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.brand:hover {
  -webkit-transform: scale3d(1.04, 1.04, 1.01);
  transform: scale3d(1.04, 1.04, 1.01);
}


.home-hero-v3-wrapper {
  position: relative;
  overflow: hidden;
  padding: 159px 105px 47px;
  border-radius: 50px;
  box-shadow: 0 17px 84px 0 rgba(20, 20, 43, 0.03);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

/* Responsive */
@media only screen and (max-width: 991px) {
  .report-sidebar {
    display: none;
  }
}